import { faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import styled from 'styled-components';

import { cta, danger, fontMedium, info } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { login } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Checkbox from '@elements/Checkbox';
import Input from '@elements/Input';
import Title from '@elements/Title';
import { useSignUpMutation, SignUpInput } from '@graphql/generated/graphql';
import useMediaQuery from '@hooks/useMediaQuery';

const Component = styled.div`
  margin: 0 auto;
  padding: ${space * 5}px ${space * 3}px;
  text-align: center;

  input {
    font-size: 16px;
  }

  ${tablet} {
    padding: ${space * 7}px ${space * 5}px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${cta};
`;

const Login = styled.a`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: ${info};
  margin-top: ${space}px;
  svg {
    margin-left: ${space}px;
  }
`;

const ApiErrors = styled.div<{ show: boolean }>`
  font-size: 12px;
  color: ${danger};
  height: 14px;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const MarkeitngText = styled.span`
  color: ${fontMedium};
  text-align: left;
  font-weight: 500;
`;

const SignUpForm = () => {
  const [signUp, { error }] = useSignUpMutation();
  const { isMobile } = useMediaQuery();
  const router = useRouter();
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      acceptsMarketing: false,
      email: '',
    },
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = formMethods;

  const onSubmit: SubmitHandler<SignUpInput> = async (data) => {
    // Same path with no dialog hash
    const newPath = router.asPath.replace(/#[\w-]+/g, '');
    signUp({
      variables: { input: data },
      onCompleted: (data) => {
        //TODO: better way to get latest account data?
        Cookies.set('accessToken', data?.signUp?.accessToken);
        // if (isMobile) {
        //   Cookies.set('mobileUser', 'true');
        // }
        router.push(newPath);
        router.reload(); // reloading to update user data
      },
      onError: (error) => {
        setError('email', { type: 'api', message: error.message });
      },
    });
  };

  const apiErrorMessage = () => {
    switch (errors.email?.message) {
      case 'ALREADY_EXISTS':
        return 'Er bestaat al een account met dit emailadres';
      default:
        return 'Er is iets misgegaan';
    }
  };

  return (
    <Component>
      <Icon icon={faCircleUser} size="3x" />
      <Title size="nm" as="h3" className="mt-16">
        Account aanmaken
      </Title>
      <Link href={login} passHref>
        <Login>
          Ik heb al een account
          <FontAwesomeIcon icon={faArrowRightLong} />
        </Login>
      </Link>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input name="firstName" label="Voornaam" className="mt-16" required />
          <Input
            name="lastName"
            label="Achternaam"
            className="mt-16"
            required
          />
          <Input
            name="email"
            label="E-mailadres"
            type="email"
            className="mt-16"
            required
          />
          <Input
            name="password"
            label="Wachtwoord"
            type="password"
            className="mt-16"
            required
            showError
          />
          <Controller
            name="acceptsMarketing"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Checkbox name={name} onChange={onChange} checked={value}>
                <MarkeitngText>
                  Ja, ik wil €5 korting en leuke tips ontvangen!
                </MarkeitngText>
              </Checkbox>
            )}
          />
          <ApiErrors show={!!error && !!errors?.email}>
            {errors?.email && apiErrorMessage()}
          </ApiErrors>
          <Button
            type="submit"
            width="100%"
            className="mt-16"
            data-gtm="create_account"
          >
            Account aanmaken
          </Button>
        </form>
      </FormProvider>
    </Component>
  );
};

export default SignUpForm;
